<template>
	<div id="login">
		<div class="col-12 col-lg-4 lateral">
			<Logo/>
			<v-form ref="form_usuario">
				<div class="row">
					<div class="col-12">
						<h2>
							Olá! Seja bem-vindo(a)
						</h2>
						<h3>
							Enviamos um código para seu e-mail. Por favor digite o código.
						</h3>
					</div>
					<div class="col-12">
						<v-text-field v-mask="'####'" type="text" name="codigo" v-model="input.codigo" :rules="[v => !!v || 'Código incorreto']" label="Código" placeholder="Código" outlined/>
					</div>
					<div class="col-6 offset-6 recuperar-senha">
						<router-link to="/login">Voltar</router-link>
					</div>
					<div class="col-12">
						<v-btn type="submit" block elevation="2" large color="primary" :loading="loading">
							Enviar
						</v-btn>
					</div>
				</div>
			</v-form>
		</div>
		<div class="col-8 imagem background animated">
			<LoginDireita/>
		</div>
		<Loader v-if="loading"/>
	</div>
</template>

<script>
	import Logo from "@/components/Logo.vue"
	import LoginDireita from "@/components/LoginDireita.vue"
	import Loader from "@/components/Loader.vue"
	import store from '@/store'
	import {mask} from 'vue-the-mask'
	
	export default {
		name: 'RecuperarSenhaCodigo',
		components: {
			Logo,
			Loader,
			LoginDireita
		},
		directives: {mask},
		data() {
            return {
				loading: false,
                input: {
                    codigo: ""
                },
                validacao: {
                    codigo: false
                }
            }
        },
        methods: {
			changeLoading(value) {
				this.loading = value
			},
			handleSubmit() {
				this.changeLoading(true)
                if(this.input.codigo != "") {
					store.dispatch('recuperarSenhaValidarCodigo', this.input).then(()=>{
						this.changeLoading(false)
						this.validacao.codigo = false
					})
                } else {
					this.changeLoading(false)
                    this.validacao.codigo = true
                }
            }
        }
	}
</script>


<style lang="scss">
	#login{
		flex: 1;
		display: flex;
		max-height: 100vh;
		.lateral{
			form{
				padding: 50px;
				h2{					
					font-style: normal;
					font-weight: 500;
					font-size: 22px;
					line-height: 36px;
					color: var(--primary-color);
				}
				.lembrar-usuario{
					display: flex;
					align-items: center;
					label{
						display: flex;
						align-items: center;
						margin-bottom: 0;
						p{
							padding: 0px;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 17px;
							color: #373737;
						}
						input{
							width: auto;
							margin-right: 10px;
						}
					}
				}
				.recuperar-senha{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					text-align: right;
					a{
						text-align: right;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 20px;
						color: rgba(0,0,0,.6);
					}
				}
				@media (max-width: 1400px){
					padding: 0 15px;
				}
			}
		}
		.imagem{
			flex: 1;
		}
	}
</style>